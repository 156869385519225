<template>
  <div>
    <div class="backgroundOV"></div>
    <v-card outlined>
      <v-card-title class="titleLarge">Einspluseins</v-card-title>
    </v-card>
    <br />
    <br />
    <v-card outlined>
      <v-card-title class="titleSmaller">
        Was erwartet Sie im Inhaltsbereich Einspluseins?
      </v-card-title>
      <v-card-text>
        Dem Einspluseins liegen drei wesentliche Aspekte zugrunde. Diese gelten
        weithin als Grundlage zur Ausbildung eines umfassenden Verständnisses
        für das Einspluseins. Daher wird im Hintergrundwissen auf diese drei
        Bereiche eingegangen:
        <ol>
          <li>Grundvorstellungen der Addition</li>
          <li>Nutzen von Aufgabenbeziehungen</li>
          <li>Darstellungen vernetzen</li>
        </ol>
        Im Anschluss werden an einigen Schüler:innenbeispielen exemplarisch
        Diagnose- und Förderungsaufgaben an Sie gestellt, sodass Sie die
        Facetten des Hintergrundwissens anwenden können.
        <br />
        Den Abschluss bildet eine Kompetenzübersicht, sodass noch einmal
        transparent wird, was im Inhaltsbereich Einspluseins zentral ist.
      </v-card-text>
    </v-card>
    <v-row no-gutters justify="center" align="center">
      <p class="white--text" style="z-index: 1; padding-top: 15px">
        Im unteren Bereich finden Sie die Navigation.
      </p>
    </v-row>
    <v-row no-gutters justify="center" align="center">
      <v-icon x-large class="white--text">mdi-arrow-down</v-icon>
    </v-row>
    <AppBottomNavEPE next="/einspluseins/hintergrundwissen" />
  </div>
</template>

<script>
import AppBottomNavEPE from "@/common/AppBottomNavEPE";
export default {
  components: {
    AppBottomNavEPE,
  },
};
</script>
<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
