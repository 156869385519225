<template>
  <AppBottomNav :items="items" :back="back" :next="next" />
</template>

<script>
import AppBottomNav from "./AppBottomNav";

export default {
  props: {
    back: String,
    next: String,
  },
  components: {
    AppBottomNav,
  },
  data() {
    return {
      items: [
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Einspluseins",
          to: "/einspluseins",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Hintergrundwissen",
          to: "/einspluseins/hintergrundwissen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Grundvorstellungen der Addition",
          to: "/einspluseins/hintergrundwissen/grundvorstellungen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Aufgabenbeziehungen nutzen",
          to: "/einspluseins/hintergrundwissen/aufgabenbeziehungen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Darstellungen vernetzen",
          to: "/einspluseins/hintergrundwissen/darstellungen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Übergang Diagnose",
          to: "/einspluseins/uebergang",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Diagnose & Förderung",
          to: "/einspluseins/diagnose-und-foerderung",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Kompetenzliste",
          to: "/einspluseins/check",
        },
      ],
    };
  },
};
</script>
